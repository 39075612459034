const BUTTON_OFFSET = 17;

export function setupTables() {
    var table_wrapper = document.querySelector('.search-results-wrapper');

    if (!table_wrapper) {
        return;
    }

    // fix the width of the header cells
    var table = table_wrapper.querySelector('table');
    var thead = table.querySelector('thead');
    var tbody = table.querySelector('tbody');
    var header_cells = thead.querySelectorAll('th');
    var table_rows = tbody.querySelectorAll('tr');
    var first_row = table_rows[0].querySelectorAll('td');
    first_row.forEach(function (cell, i) {
        var width = window.getComputedStyle(cell).width;
        header_cells[i].style.width = width;
    });
    // make the table fixed
    table.style.width = `${thead.getBoundingClientRect().width}px`;
    table.style.tableLayout = 'fixed';

    // make a copy of the table with just thead
    var table_copy = table.cloneNode(true);
    table_copy.querySelector('tbody').remove();
    table_copy.classList.add('fixed-header');
    table_copy.style.marginBottom = '0';

    // add a wrapper for the copy
    var table_copy_wrapper = document.createElement('div');
    table_copy_wrapper.style.backgroundColor = 'white';
    table_copy_wrapper.style.overflow = 'hidden';
    table_copy_wrapper.style.width = `${table_wrapper.getBoundingClientRect().width}px`;

    // add the table copy to the wrapper
    table_copy_wrapper.appendChild(table_copy);

    // insert the copy before the original table
    table_wrapper.insertBefore(table_copy_wrapper, table_wrapper.querySelector('table'));

    // get the left offset of the original table
    var table_offset_left = thead.getBoundingClientRect().left;
    var table_offset_top = thead.getBoundingClientRect().top;

    // fix the new table to the top of the viewport
    table_copy_wrapper.style.position = 'fixed';
    table_copy_wrapper.style.top = '0';
    if (table_offset_top < BUTTON_OFFSET) {
        table_copy_wrapper.style.opacity = '1';
    } else {
        table_copy_wrapper.style.opacity = '0';
    }
    // table_copy.style.top = `${table_offset_top}px`;
    table_copy_wrapper.style.left = `${table_offset_left}px`;
    table_copy_wrapper.style.zIndex = '2';

    // when the original header scrolls horizontally, scroll the copy
    table_wrapper.addEventListener('scroll', function () {
        // table_copy_wrapper.style.left = `${table_offset_left - table_wrapper.scrollLeft}px`;
        table_copy_wrapper.scrollLeft = table_wrapper.scrollLeft;
    });

    // when the body scrolls vertically, show or hide the copy
    window.addEventListener('scroll', function () {
        var table_offset_top = thead.getBoundingClientRect().top;
        if (table_offset_top < BUTTON_OFFSET) {
            table_copy_wrapper.style.opacity = '1';
        } else {
            table_copy_wrapper.style.opacity = '0';
        }
    });

}