import { czPieChart, czFundsBarChart } from './charts.js';

function createIncomeChart() {
    var ctx_income_chart = document.getElementById('income-chart');
    if (!CZ_DATA["income_chart"] || !ctx_income_chart) {
        return;
    }
    return czPieChart(ctx_income_chart, CZ_DATA["income_chart"], legend = false);
}

function createSpendingChart() {
    var ctx_spending_chart = document.getElementById('spending-chart');
    if (!CZ_DATA["spending_chart"] || !ctx_spending_chart) {
        return;
    }
    return czPieChart(ctx_spending_chart, CZ_DATA["spending_chart"], legend = false);
}


function createFundsChart() {
    var ctx_funds_chart = document.getElementById('funds-chart');
    if (!CZ_DATA["funds_chart"] || !ctx_funds_chart) {
        return;
    }
    var funds_chart = czFundsBarChart(ctx_funds_chart, CZ_DATA["funds_chart"], legend = false);
}

export default function createCharts() {
    createIncomeChart();
    createSpendingChart();
    createFundsChart();
}