import { setupCopyText } from './copytext.js'
import { setupTables } from './tables.js'
import './setupAlpine.js'
import './setupHtmx.js'
import './sidebarcards.js'
import { default as createDashboardCharts } from './charts-dashboard.js'
import { default as createOverviewCharts } from './charts-charity-overview.js'
import { default as createFinanceCharts } from './charts-charity-finances.js'
import { createMiniExpenditureChart, createMiniIncomeChart } from './charts-checks-finances.js'


function main() {
    setupCopyText();
    createDashboardCharts();
    createOverviewCharts();
    createFinanceCharts();
    createMiniExpenditureChart();
    createMiniIncomeChart();
    setupTables();
}

document.addEventListener("htmx:afterSettle", function (event) {
    main();
});

document.addEventListener("DOMContentLoaded", function (event) {
    main();
});