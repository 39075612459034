import 'htmx.org'
import { createToast } from './htmx-toast.js'

window.htmx = require('htmx.org');

document.body.addEventListener('htmx:beforeSwap', function (event) {
    if (event.detail.xhr.status === 422) {
        const targetError = event.detail.requestConfig.elt.attributes.getNamedItem('hx-target-error').value;
        if (targetError) {
            event.detail.shouldSwap = true;
            event.detail.target = htmx.find(targetError);
        }
    }
});

document.body.addEventListener('htmx:responseError', function (event) {
    createToast({
        title: event.detail.xhr.status + ": " + event.detail.xhr.statusText,
        message: "Problem loading page",
        tags: "error"
    })
});

htmx.on("messages", (event) => {
    event.detail.value.forEach((message) => {
        createToast(message)
    })
})

// Remove elements with x-from-template attribute when history is restored
// https://github.com/alpinejs/alpine/discussions/2924#discussioncomment-3877977
htmx.on('htmx:historyRestore', (evt) => {
    document.querySelectorAll('[x-from-template]').forEach((e) => e.remove())
})
