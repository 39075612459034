
document.querySelectorAll(".sidebar-card details").forEach(function (details) {
    details.classList.add("js-animate");
    if (details.open) {
        details.classList.add("show");
    }
    details.addEventListener("toggle", (event) => {
        if (details.open) {
            details.classList.add("show");
        } else {
            details.classList.remove("show");
        }
    });
});