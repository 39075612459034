export function createToast(message) {
    // Clone the template
    const element = Array.from(
        document.querySelector("#toast-template").content.cloneNode(true).childNodes
    ).filter(node => node.nodeType === 1)[0]
    const container = document.querySelector("#toast-container")

    // Set the CSS class
    element.classList.add(message.tags)

    // Set the title
    if (message.title) {
        element.querySelector("h4").innerText = message.title
    } else {
        element.querySelector("h4").remove()
    }

    // Set the text
    element.querySelector("p").innerText = message.message

    // Add the new element to the container
    container.appendChild(element)

    // Add the close button event listener
    element.querySelector(".close-button").addEventListener("click", () => {
        element.remove()
    })

    // Remove the element after 5 seconds
    setTimeout(() => {
        element.remove()
    }, 5000)

}