import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'

window.Alpine = Alpine

Alpine.plugin(mask)

document.addEventListener('alpine:init', () => {
    Alpine.data('filterbox', () => ({
        selectedBox: 0,
        showPrevious: true,
        showNext: true,
        numberOfBoxes() {
            return this.$refs.boxes.querySelectorAll(".filter-box").length;
        },
        selectNextBox() {
            this.$refs.boxes.scrollBy({
                top: 0,
                left: 150,
                behavior: "smooth",
            });
        },
        selectPreviousBox() {
            this.$refs.boxes.scrollBy({
                top: 0,
                left: -150,
                behavior: "smooth",
            });
        },
        init() {
            this.checkFirstElement = new IntersectionObserver((entries) => {
                this.showPrevious = (entries[0].intersectionRatio <= 0);
            }, { threshold: 0, root: this.$refs.boxes, rootMargin: "0px 0px 0px -200px" });
            this.checkLastElement = new IntersectionObserver((entries) => {
                this.showNext = (entries[0].intersectionRatio <= 0);
            }, { threshold: 0, root: this.$refs.boxes, rootMargin: "0px -200px 0px 0px" });
            var boxes = this.$refs.boxes.querySelectorAll(".filter-box")
            this.checkFirstElement.observe(boxes[0]);
            this.checkLastElement.observe(boxes[boxes.length - 1]);
        },
    }));

    Alpine.data('checkboxes', () => ({
        selectAll: false,
        selectedItems: [],
        openFields: false,

        selectAllCheckboxes() {
            this.selectAll = !this.selectAll

            let checkboxes = document.querySelectorAll('input[type=checkbox][name=selected-charity]');
            let allValues = [];

            [...checkboxes].map((el) => {
                allValues.push(el.value)

                this.selectedItems = this.selectAll ? allValues : []
            })
        }
    }))
});


Alpine.start()