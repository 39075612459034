import { czMiniFinanceChart } from './charts.js';

export function createMiniExpenditureChart() {
    var ctx = document.getElementById('expenditure-change-over-time');
    if (!CZ_DATA["financial_history"] || !ctx) {
        return;
    }
    var finances = CZ_DATA["financial_history"].slice(-5);

    var color = '#F6306C';
    if (finances[0].spending < finances[finances.length - 1].spending) {
        color = '#68C7C3';
    }

    return czMiniFinanceChart(
        ctx,
        finances.map((x) => x.fye.slice(0, 4)),
        finances.map((x) => x.spending),
        'Spending',
        color
    );
}

export function createMiniIncomeChart() {
    var ctx = document.getElementById('income-change-over-time');
    if (!CZ_DATA["financial_history"] || !ctx) {
        return;
    }
    var finances = CZ_DATA["financial_history"].slice(-5);

    var color = '#F6306C';
    if (finances[0].income < finances[finances.length - 1].income) {
        color = '#68C7C3';
    }

    return czMiniFinanceChart(
        ctx,
        finances.map((x) => x.fye.slice(0, 4)),
        finances.map((x) => x.income),
        'Income',
        color
    );
}