import { Chart } from 'chart.js/auto';
import htmlLegendPlugin from './charts-html-legend.js';

Chart.defaults.font.size = 14;
Chart.defaults.font.family = '"Montserrat", sans-serif';

export default Chart;

export const MONEY = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 });

export function czHorizontalBarChart(ctx, data) {
    return new Chart(ctx, {
        type: 'bar',
        data: {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    label: 'Charity count',
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.colour),
                    borderRadius: 20,
                    barThickness: 24,
                }
            ]
        },
        options: {
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        padding: 0,
                        mirror: true,
                        labelOffset: -24,
                    },
                    border: {
                        display: false,
                    }
                }
            }
        },
    });
}

export function czPieChart(ctx, data, legend = null) {
    return new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    label: 'Charity count',
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.colour),
                }
            ]
        },
        options: {
            borderWidth: 0,
            responsive: true,
            plugins: {
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: legend,
                },
                legend: {
                    display: false,
                },
            },
            cutout: '33%',
        },
        plugins: [htmlLegendPlugin],
    });
}


export function czFundsBarChart(ctx, data) {
    return new Chart(ctx, {
        type: 'bar',
        data: {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    label: 'Charity count',
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.colour),
                }
            ]
        },
        options: {
            indexAxis: 'x',
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    display: false,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    }
                }
            }
        },
    });
}

export function czMiniFinanceChart(ctx, labels, data, label = 'Spending', color = '#F6306C') {
    return new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: label,
                data: data,
                fill: false,
                lineTension: 0,
                borderColor: color,
                pointRadius: 1,
                hitRadius: 5,
            }]
        },
        options: {
            plugins: {
                legend: {
                    display: false,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        boxHeight: 1,
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: false,
                    ticks: {
                        count: 10,
                        display: false,
                        padding: 0,
                    },
                    grid: {
                        display: true,
                    },
                },
                x: {
                    ticks: {
                        stepSize: 24,
                        font: {
                            size: 12
                        }
                    },
                    distribution: 'series',
                    grid: {
                        display: false,
                    }
                }
            }
        }
    });
}